import * as React from 'react';
import '../App.css';
import { TextField, Button, Typography } from '@mui/material';
import  FormControl, { useFormControl } from '@mui/material/FormControl';
import { useEffect } from 'react';

const code1 = "LISTEN";
const code2 = "ACCESS";
const code3 = "MARKETS";
const code4 = 'MAILTEST';


function Code({advanceFunction}) {



  const increase = () => {
      setCount(prevCount => {
          const newCount = Number(prevCount) + 1;
          localStorage.setItem("count", newCount);
          return newCount;
      });
  }

  useEffect(() => {
      const initialValue = localStorage.getItem("count");
      if (initialValue) setCount(initialValue);
  },[]);

//   console.log(localStorage.getItem('count'));

  const [code, setCode] = React.useState('ACCESS');



  const [checkCode, setCheckCode] = React.useState('');

  const [bool, setBool] = React.useState(false);

  const[count, setCount] = React.useState(0);


  const handleChange = (event) => {
    setCheckCode(event.target.value);
  }

  const handleSubmit = (val) => {

      //reset count


    // localStorage.setItem("count", 0);
    // setCount(0);

    const isBool = bool;

    if (val === 'dev'){
      localStorage.setItem("count", 0);
      setCount(0);
      console.log('reset values')
    }

    if (val === code1 || val === code2 || val === code3 || val === code4) {
      console.log('done')


      setBool(false)
      increase()
      if (count >= 4) {
          //reached limit on views

      } 
      else {
        advanceFunction()
      }

      if(val === code4){
        setCount(0);
      }
      console.log(count)
      console.log(bool)
    }
    else {
      console.log('Code incorrect')
      setBool(true)
      console.log(bool)

    }
  }

  const buttonShow = () => {
    if (count >= 4) {
        return  <Button href="https://traderphd.com/?utm_source=directmarketing&utm_medium=directmail&utm_campaign=vintage/#trial" variant='contained' color='primary' >START FREE TRIAL</Button>
    }
    else{
        return
    }
  }

  return (

      <div className="sub-title-box">
        <div className='ct-width'>
          {/* <h2 className="sb-txt">April cattle on feed up 1.7% y/o/y as placements decline slightly</h2>
          <img src='assets/images/unnamed.png' className='img-1'/>
          <p className="tl-txt">
          The USDA released its monthly cattle on feed report, showing that total cattle on feed in the U.S. rose 1.7 percent year-over-year to 12.1 million head as of April 1, where traders expected the herd to increase 0.4 percent.<br/><br/>

          March feedlot placements fell -0.4 percent from last year to 1.99 million head. Traders expected a larger drop of -7.7 percent. Cattle marketings were down 2 percent from last year, sitting at 2 million head.<br/><br/>

          This month’s numbers are still supportive of liquidation happening in the cattle industry. Many producers are finding that drought-inflicted ranges can no longer support current herd sizes. Texas had a 24 percent increase in feedlot placements from last month after the drought has worsened significantly in the state.<br/><br/>

          Higher cattle on feed numbers are contributing to increased cattle slaughter and holding at the same rate in March of 2021. Beef production also ticked up one percent to 2.51 billion pounds.<br/><br/>
          </p>
          <img src='assets/images/unnamed-1.png' className='img-1'/>
          <p className='tl-txt in-line'>Beef in cold storage is up 11 percent from 2021, bringing larger supplies into the retail market and indicating a bearish outlook for the short term. Total pork in cold storage rose 8 percent, the first year-over-year increase since September.
            Gain access to coverage like this, and more, by downloading the <a href="https://traderphd.com/app/">Trader PhD app</a> or calling 515-999-5522.</p> */}

            <div className='border-box center-center'>
              <FormControl>
                <TextField 
                  error={bool}
                  helperText={bool === false ? "Enter Code Here" : "Incorrect Code"}
                  id="outlined-basic"
                  label="Access Code"
                  varient="Outlined" 
                  size='large' 
                  className="textBox"
                  value={checkCode}
                  onChange={handleChange}
                  sx={{
                    color: 'black'
                  }}
                />
              </FormControl>
              <Button 
                variant='contained'
                color='primary' 
                className="bt-1"
                onClick={() => handleSubmit(checkCode.toUpperCase())}
                sx={{
                  width: 200,
                  marginTop: 2,
                  marginBottom: 2
                }}
                >Submit
              </Button>
              <h2 hidden={count >= 4 ? false:true}>
                  Limit reached on views!
              </h2>
              {buttonShow()}
              <Typography
              hidden={count >= 4 ? true:false}
              marginTop={1}
              >Don't have a code? Click <a href='https://traderphd.com/?utm_source=directmarketing&utm_medium=directmail&utm_campaign=vintage/#trial'>here</a> to start your free trial and access our content.</Typography>

            </div>


        </div>

      </div>
  );
}

export default Code;
