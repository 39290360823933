import * as React from 'react';
import '../App.css';
import { Button } from '@mui/material';
import  FormControl, { useFormControl } from '@mui/material/FormControl';

function Final() {
    return (
        <div>
            <h1>Thanks for listening!</h1>
            <p>Start free trial with us to access all our content</p>
            <Button
            href='https://www.traderphd.com/#trial'
            variant='contained'
            color='primary' 
            sx={{
                width: 200,
                marginTop: 2,
                marginBottom: 2
            }}
            >START MY FREE TRIAL</Button>
        </div>
    );
}

 
export default Final;