import * as React from 'react';
import '../App.css';
import { Button } from '@mui/material';


function Video({advanceForward}) {

    const myCallback = () => {
        console.log('Function executed')
        advanceForward()
    }

    return (
        <div>
            <video width='100%' height="100%" controls onEnded={myCallback} controlsList="nodownload" autoPlay={true}>
                 <source src='../assets/images/062023_Broadcast_v2a.mp4' type='video/mp4'/>
             </video>

             <Button 
                href="https://traderphd.com/?utm_source=directmarketing&utm_medium=directmail&utm_campaign=vintage/#trial"
                variant='contained'
                 color='primary'
                 sx={{
                    width: 200,
                    marginTop: 2,
                  }}
                 >START FREE TRIAL</Button>
        </div>

        

        // <iframe width="auto" height="auto" onEnded={() => myCallback()} src="https://www.youtube.com/embed/LjZ-PrcjKYM" title="YouTube video player" frameBorder="0" allowFullScreen="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
    );
}
 
export default Video;