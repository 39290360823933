import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../App.css';


const Content = () => {

    const theme = createTheme();

    return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              <Typography component="h1" variant="h5" align="center">
              Be prepared for what is ahead in the commodity markets. Follow along as you hear us explain what we're seeing develop, and how to handle it.
              </Typography>

              <h2>
                    Why Farmers Love us
              </h2>
                <Typography component="h2" varient="h2" align="center">
                1. Not a broker
                </Typography>
                <Typography component="h2" varient="h2" align="center">
                2. Selective buy/sell signals
                </Typography>
                <Typography component="h2" varient="h2" align="center">
                3. Customizable subscriptions
                </Typography>
                <Typography component="h2" varient="h2" align="center">
                4. Accessibility options
                </Typography>
                <Typography component="h2" varient="h2" align="center">
                5. Three decades of trading experience
                </Typography>

                <h2>
                    Client testimonials
                </h2>

                <Typography component="h1" varient="h5" align="center">
                    93% of our subscribers renew their subscription with us year-after-year, even though we don't do auto renewals. See what our subscribers are saying.
                </Typography>
                <br/>
                <Typography component="h2" varient="h2" align="center">
                    "I gotta say, the first month I've been with him he's been hitting it right on the money. I was pretty impressed so far, he's been accurate. And I gotta say the other two outfits I've been with weren't near as accurate as he was. I mean, when he says hedge your corn or sell it, or go short the market or whatever, he's exactly right."
                       <br/> Dan N., Nebraska
                </Typography>
                <br/>
                <Typography component="h2" varient="h2" align="center">
                "You're like a good preacher. I'm a preacher myself, but you're like a good preacher. You keep this stuff in my mind all the time because we're so busy with other stuff. But that's why I enjoy your service, because you hit me usually when I need to be hit. I just want to tell you I appreciate it."
                <br/>Mark F., Kansas
                </Typography>
                <Typography component="h2" varient="h2" align="center">
                "He's providing an unparalleled look into the agricultural market for farmers today. He presents it in a way that farmers can understand and make more use of the markets." 
                <br/>Nathan K., Minnesota 
               </Typography>
               <br/>
                <Typography component="h2" varient="h2" align="center">
                "I like the way he talks, the way he presents himself - I'm very impressed with that. And it's very convenient. Definitely one of the better options I've seen so far. I think he's done his homework."
                <br/>Mark B., Illinois
                </Typography>
                <br/>
                <Typography component="h2" varient="h2" align="center">
                "It really got me thinking about some of the advice he gave more recently. He called hogs lower and weaker, and they did! That's just one of the things I remember and there are many more, but I've gotta thank him for calling the right avenue here."
                <br/>Matt D., Minnesota
                </Typography>
                <h2>
                    Primary Features
                </h2>
                <div className='center-center'>
                    <div className='ct-items-w'>
                        <ul>
                            <li>Audio commentary and texts</li>
                            <li>Sell signals</li>
                            <li>Charts</li>
                            <li>News and special reports</li>
                            <li>Weather updates</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <Typography component="h1" variant="h5" align="center">
                Join thousands of subscribers and sign up for your free trial today!
              </Typography>
            </Paper>
          </Container>
        </ThemeProvider>
      );    
}

export default Content;